@import 'sass/theme';

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    width: 100%;
    transition: opacity 0.3s linear;
    opacity: 0;

    &:not(:global(.home)) {
        padding-top: 60px; }

    &:global(.home) {
        padding-top: 100vh; }

    &:global(.in) {
        opacity: 1; }

    &:global(.out) {
        position: absolute;
        width: 100%;
        transition-duration: 0.2s;
        z-index: -1;
        opacity: 0; }

    > div {
        animation: animate-content 0.5s ease-out forwards; } }

@keyframes animate-content {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }
