.home {
     :global {
        .header {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center top;

            video {
                position: absolute;
                height: 100%;
                width: 177.77777778vh;
                min-width: 100%;
                min-height: 56.25vw;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%); } }

        .content {
            position: relative;
            z-index: 1;
            background: #D2E0D5;
            padding-top: 86px;

            .mute {
                position: absolute;
                right: 20px;
                top: -50px;
                cursor: pointer; } }

        .logo {
            position: fixed;
            left: 44px;
            top: 100%;
            margin-top: -176px;
            width: 221px;
            height: 146px;
            background: url(../../../../media/logo2.svg);
            background-size: contain;
            background-repeat: no-repeat; }

        .instagram {
            margin-top: 100px;
            position: relative;
            min-height: 600px;

            .border {
                bottom: 140px; }

            > .more {
                text-align: center;
                margin-top: 40px; }

            .sb_instagram_header {
                display: none; }


            #sbi_images {
                display: flex;
                flex-wrap: wrap;
                margin-left: -30px;

                > li {
                    width: 33.3333%;

                    > div {
                        position: relative;
                        margin-top: 30px;
                        margin-left: 30px;
                        transition: transform 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                        > div {
                            padding-bottom: 100%;
                            background-size: cover;
                            background-position: center top; }

                        &:hover {
                            transform: scale(1.1);
                            transition-duration: 0.3s; } } }

                > li:nth-child(n+10) {
                   display: none; } }

            &.more {
                #sbi_images {
                    > li:nth-child(n+10) {
                        display: block; }

                    > li:nth-child(n+19) {
                        display: none; } } }

            #sbi_load {
                display: none; } } } }
