@import 'sass/theme';

.contact {
    width: 100%;

     :global {
        .text {
            margin-top: 88px;
            padding-bottom: 100px;

            > .border {
                top: 58px; } }


        .text > div {
            display: flex;
            flex-wrap: wrap;

            > div:nth-child(1) {
                position: relative;
                flex: 0 0 100%;
                padding-bottom: 50.5859375%;
                margin-bottom: 10.45%;

                > div {
                    position: absolute !important; } }

            > div:nth-child(2) {
                flex: 0 0 50%;
                padding-right: 8%; }

            > div:nth-child(3) {
                flex: 0 0 50%;

                > div {
                    max-width: 404px; } } } } }






