@import 'sass/theme';

.navigation {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    padding: 141px 20px 20px 41px;
    background: #000;
    transform: translateX(260px);
    transition: transform 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    overflow-y: auto;

    &:global(.show-navigation) {
        transform: none; }

    > ul {
        a {
            text-transform: uppercase;
            color: #fff;

            &:global(.active),
            &:hover {
                color: $color-secondary; } }

        li {
            display: block; }

        &:nth-child(1) {
            li {
                font-size: 1.375rem;
                line-height: 109%;

                + li {
                    margin-top: 43px; } } }

        &:nth-child(2) {
            margin-top: 40px;

            li {
                font-size: 0.75rem;
                line-height: 166%;

                + li {
                    margin-top: 17px; } } } }

     :global {
        .social {
            margin-top: 40px;

            h6 {

                font-size: 0.875rem;
                font-weight: 300;
                color: #fff; }

            ul {
                margin-top: 22px;

                li {
                    display: inline-block;

                    + li {
                        margin-left: 37px; } } }

            svg path,
            svg polyline {
                fill: #fff; }

            a:hover {
                svg path,
                svg polyline {
                    fill: $color-secondary; } } } } }




#header {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background: rgb(188, 203, 191);
    transition: opacity 0.5s;

    @at-root :global(.home)#{&} {
        opacity: 0; }

    @at-root :global(.scrolled)#{&} {
        opacity: 1; }

     :global {
        .logo {
            display: block;
            margin-left: 44px;
            width: 74px;
            height: 39px;
            background: url(../../media/logo.svg); } } }

.menu {
    position: fixed;
    z-index: 101;
    right: 40px;
    top: 20px;
    width: 24px;
    height: 20px;
    cursor: pointer;

    span,
    &:before,
    &:after {
        display: block;
        position: absolute;
        left: 2px;
        top: 9px;
        width: 20px;
        height: 2px;
        background: #000;
        content: '';
        transition: transform .4s; }

    &:before {
        top: 2px; }

    &:after {
        top: 16px; }

    &:global(.active) {
        span,
        &:before,
        &:after {
            background: #fff; }

        span {
            transform: rotate(-45deg);
            opacity: 0; }

        &:before {
            transform: rotate(-45deg)  translate(-5px, 5px); }

        &:after {
            transform: rotate(45deg)  translate(-5px, -5px); } }

    &:hover {
        span,
        &:before,
        &:after {
            background: $color-secondary; } } }

.casting {
    position: fixed;
    z-index: 99;
    right: 100px;
    top: 17px;
    padding-right: 36px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1rem;

    span {
        position: absolute;
        display: block;
        right: 0;
        top: 2px;
        width: 23px;
        height: 23px;
        background: $color-secondary;
        border-radius: 50%;
        text-align: center;
        line-height: 23px;
        font-size: 0.625rem; } }





