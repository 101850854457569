@import 'sass/theme';

.cookie {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

    > div {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #bccbbf; }

     :global {
        .center > div {
            display: flex;
            margin-top: 20px;
            margin-bottom: 20px;

            div:nth-child(2) {
                flex: 0 0 200px;

                .button {
                    display: block; } } } } }
