@import 'sass/theme';

.modelList {
    padding-top: 58px;

     :global {
        .actions {
            text-align: center;

            & + .actions {
                margin-top: 20px; } }

        .list {
            position: relative;
            margin-top: 37px;
            padding-top: 25px;

            .border {
                bottom: 87px; }

            ul {
                position: relative;
                margin-left: -30px;


                li {
                    position: absolute;
                    transition: all 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                    @at-root :global(.noanimate)#{&} {
                        transition-duration: 0s; }


                    > div {
                        position: relative;
                        margin-left: 30px;
                        padding-bottom: 50px;

                        > .image {
                            position: relative;
                            overflow: hidden;
                            z-index: 1;
                            padding-bottom: 100%;
                            background: #bccbbf;
                            transition: transform 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940), opacity 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                            > div {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background-size: cover;
                                background-position: center top; } }

                        h3 {
                            position: relative;
                            top: 9px;
                            margin-bottom: 30px;
                            z-index: 2;
                            font-size: 1rem;
                            text-transform: uppercase;
                            transition: all 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940); }

                        .casting {
                            display: block;
                            position: absolute;
                            z-index: 11;
                            top: 10px;
                            right: 10px;
                            width: 24px;
                            height: 24px;
                            border: 2px $color-secondary solid;
                            border-radius: 50%;
                            cursor: pointer;

                            @media (hover: hover) {
                                display: none; }

                            &::before,
                            &::after {
                                position: absolute;
                                left: 3px;
                                top: 9px;
                                display: block;
                                width: 14px;
                                height: 2px;
                                background: $color-secondary;
                                content: ""; }

                            &::after {
                                transform: rotate(90deg); }

                            &.active::after {
                                display: none; }

                            &:hover {
                                background: $color-secondary;

                                &::before,
                                &::after {
                                    background: #000; } } }

                        @media (hover: hover) {
                            &:hover {
                                > div {
                                    transform: scale(1.1);
                                    transition-duration: 0.3s;

                                    > div {
                                        transform: scale(1.05); } }

                                h3 {

                                    margin-top: 5%;
                                    transition-duration: 0.3s; }

                                .casting {
                                    display: block; } } } } } } } } }
