@import 'sass/theme';

.signup {
    width: 100%;

     :global {
        .form {
            margin-top: 88px;
            position: relative;
            padding-top: 124px;
            padding-bottom: 100px;

            > div {
                max-width: 890px;
                margin: 0 auto;

                > div {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -8%;

                    @media #{$tablet-small} {
                        margin-left: 0;
                        flex-wrap: wrap-reverse; }

                    > div {
                        flex: 0 0 50%;
                        padding-left: 8%;

                        @media #{$tablet-small} {
                            flex-basis: 100%;
                            padding: 0; } }

                    > div:nth-child(1) {
                        @media #{$tablet-small} {
                            margin-top: 40px; } } } } } } }


.form {
     :global {
        .input {
            padding-bottom: 10px;
            border-bottom: 1px solid #000;

            + .input {
                margin-top: 20px; } } }

    input[type="text"],
    textarea {
        width: 100%;
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        border: none;
        outline: none;
        background: none;
        resize: none;
        padding-left: 16px; }

    input[type="text"],
    textarea {
        &::placeholder {
            color: #000;
            opacity: 1; }

        &:-ms-input-placeholder {
            color: #000; }

        &::-ms-input-placeholder {
            color: #000; } } }




