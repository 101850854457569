.slider {
    position: relative;

    > div {
        position: relative;
        padding-bottom: 33%;
        margin-left: -30px;
        margin-right: -30px;

        @at-root :global(.columns2)#{&} {
            padding-bottom: 50%; }

        @at-root :global(.columns1)#{&} {
            padding-bottom: 100%; }

        > div {
            padding-bottom: 70px; } }

     :global {
        .slides {
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;

            > div {
                position: absolute;
                width: 100%;
                transition: transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                > div {
                    position: absolute;
                    width: 100%;

                    ul {
                        display: flex;
                        margin-left: 0;
                        margin-right: 30px;
                        flex-wrap: wrap;

                        li {
                            flex: 0 0 33.33%;

                            @at-root :global(.columns2)#{&} {
                                flex-basis: 50%; }

                            @at-root :global(.columns1)#{&} {
                                flex-basis: 100%; }

                            > div {
                                position: relative;
                                margin-left: 30px;
                                margin-top: 30px;

                                .image {
                                    position: relative;
                                    z-index: 1;
                                    padding-bottom: 100%;
                                    background-size: cover;
                                    transition: transform 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940); }

                                h3 {
                                    margin-top: 9px;
                                    margin-bottom: 30px;
                                    position: relative;
                                    z-index: 2;
                                    font-size: 1rem;
                                    text-transform: uppercase; } }

                            &:hover {
                                .image {
                                    transform: scale(1.1);
                                    transition-duration: 0.3s; } } } } } } }



        .button {
            display: block;
            position: absolute;
            top: 50%;
            width: 30px;
            height: 46px;
            transform: translateY(-50%);
            cursor: pointer;

            svg {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 20px;
                height: 36px; }

            &.previous {
                left: 40px;

                svg {
                    transform: rotate(-180deg); } }

            &.next {
                right: 40px; } } } }
