.form {
     :global {
        input[type="text"],
        textarea {
            width: 100%;
            font-family: "Helvetica Neue";
            font-weight: 400;
            font-size: 1rem;
            line-height: 150%;
            border: none;
            outline: none;
            background: none;
            resize: none;
            padding-left: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #000; }

        * + input[type="text"],
        * + textarea {
            margin-top: 40px; }

        input[type="text"],
        textarea {
            &::placeholder {
                color: #000;
                opacity: 1; }

            &:-ms-input-placeholder {
                color: #000; }

            &::-ms-input-placeholder {
                color: #000; } }

        .file {
            position: relative;
            background: rgba(9, 19, 33, 0.06);
            padding: 11px;

            input {
                float: right;
                display: none; }

            .select,
            .remove {
                float: right;
                cursor: pointer;
                font-size: 1rem;
                text-transform: uppercase;
                font-weight: 500; }

            .name {
                font-size: 1rem; }

            span {
                cursor: pointer;
                word-break: break-word;

                a {
                    text-decoration: none;
                    color: #00DDFF; } }


            &::after {
                content: "";
                display: block;
                clear: both; }

            + .file {
                margin-top: 33px; } }

        .checkbox {
            position: relative;
            margin-top: 40px;
            padding-left: 34px;

            span {
                position: absolute;
                left: 0;
                top: 0;
                width: 24px;
                height: 24px;
                background: #F4FF00;
                cursor: pointer; }

            &.active {
                span {
                    background-image: url(../../../../media/check.svg);
                    background-repeat: no-repeat;
                    background-position: 4px 6px; } }

            a {
                text-decoration: underline; } }



        .submit {
            display: inline-block;
            margin-top: 50px;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
            cursor: pointer; } } }

